"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locale = {
  placeholder: '请选择时间'
};
var _default = locale;
exports["default"] = _default;