import { stringify } from 'qs';
import request from '@/utils/request';

import axios from 'axios';
import config from '../../jest.config.js';
var crypto = require('crypto'),
    algorithm = 'aes-256-ctr',
    key = 'd6F3Efeq';

export async function queryProjectNotice() {
  return request('/api/project/notice');
}

export async function queryActivities() {
  return request('/api/activities');
}

export async function queryRule(params) {
  return request(`/api/rule?${stringify(params)}`);
}

export async function removeRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'delete',
    },
  });
}

export async function addRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'post',
    },
  });
}

export async function updateRule(params = {}) {
  return request(`/api/rule?${stringify(params.query)}`, {
    method: 'POST',
    body: {
      ...params.body,
      method: 'update',
    },
  });
}

export async function fakeSubmitForm(params) {
  return request('/api/forms', {
    method: 'POST',
    body: params,
  });
}

export async function fakeChartData() {
  return request('/api/fake_chart_data');
}

export async function queryTags() {
  return request('/api/tags');
}

export async function queryBasicProfile(id) {
  return request(`/api/profile/basic?id=${id}`);
}

export async function queryAdvancedProfile() {
  return request('/api/profile/advanced');
}

export async function queryFakeList(params) {
  return request(`/api/fake_list?${stringify(params)}`);
}

export async function removeFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'delete',
    },
  });
}

export async function addFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'post',
    },
  });
}

export async function updateFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    body: {
      ...restParams,
      method: 'update',
    },
  });
}

/* export async function fakeAccountLogin(params) {
  return request('/api/login/account', {
    method: 'POST',
    body: params,
  });
} */
//Pankaj's Changes for Authentication
export async function accountLogin(params) {
  console.log("==========>>>>>>>> ",params);

  var password = crypto.createCipher(algorithm,key).update(params.password,'utf8','hex')+crypto.createCipher(algorithm,params.password).final('hex');
  var username = crypto.createCipher(algorithm,key).update(params.userName,'utf8','hex')+crypto.createCipher(algorithm,params.userName).final('hex');
  var type = crypto.createCipher(algorithm,key).update(params.type,'utf8','hex')+crypto.createCipher(algorithm,params.type).final('hex');

   var inputData = {
    username:username,
    password:password,
    type:type
   }

   console.log("inputData ========>>>>>>> ",inputData);
   return axios({
    method: 'POST',
    url: config.serverUrl+"/authenticateUser",
    data: inputData
    })
  }

export async function fakeRegister(params) {
  return request('/api/register', {
    method: 'POST',
    body: params,
  });
}

export async function queryNotices(params = {}) {
  return request(`/api/notices?${stringify(params)}`);
}

export async function getFakeCaptcha(mobile) {
  return request(`/api/captcha?mobile=${mobile}`);
}
