module.exports = {
  testURL: 'http://localhost:8000',
  preset: 'jest-puppeteer',
 
// serverUrl : 'http://tserv01.exceloid.com:3000/app'
 
 serverUrl : 'http://116.203.154.21:3000/app'
};

//Server Side Url whe deployment is to be done on the server
// cwretail.noton.app is server 
    //"serverSideUrl":"http://tserv01.exceloid.com:3000/app"

 // Local system testing 
    //  serverUrl : 'http://localhost:3000/app'
